import React, { useEffect, useState } from 'react';
import { FormControl, Flex, Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addBankDetails,
  editBankDetails,
  fetchBankDetailsByEmployeeId,
} from '../api/manageBank';
import InputField from 'components/fields/InputField';
import SwitchField from 'components/fields/SwitchField';
import customToast from 'utils/toastUtils';
import { MdAdd, MdDelete } from "react-icons/md";
import Loader from 'components/loader/miniLoader';

const BankDetailsForm = ({ mode, employeeId }) => {
  const dispatch = useDispatch();
  const toast = customToast();

  const { newEmployeeData } = useSelector((state) => state.manageEmployees);
  const { bankDetails, isBankDetailsLoading } = useSelector((state) => state.manageBank);

  const [formState, setFormState] = useState([{
    bank_title: '',
    bank_name: '',
    account_number: '',
    iban_number: '',
    is_active: false,
  }]);

  useEffect(() => {
    if (mode === 'Edit' && employeeId) {
      dispatch(fetchBankDetailsByEmployeeId(employeeId));
    } else if (mode === 'Add') {
      setFormState([{
        bank_title: '',
        bank_name: '',
        account_number: '',
        iban_number: '',
        is_active: false,
      }]);
    }
  }, [dispatch, mode, employeeId]);

  useEffect(() => {
    if (bankDetails && mode === 'Edit') {
      const updatedBankDetails = Array.isArray(bankDetails)
        ? bankDetails.map(bank => ({
            ...bank,
            is_active: !!bank.is_active,
            bank_details_id: bank.bank_details_id || null,
          }))
        : [{
            ...bankDetails,
            is_active: !!bankDetails.is_active,
            bank_details_id: bankDetails.bank_details_id || null,
          }];
  
      setFormState(updatedBankDetails);
    }
  }, [bankDetails, mode]);

  const handleInputChange = (index, field, value) => {
    const updatedFormState = formState.map((bank, i) => 
      i === index ? { ...bank, [field]: value } : bank
    );

    if (field === 'is_active' && value === true) {
      updatedFormState.forEach((bank, i) => {
        if (i !== index) {
          bank.is_active = false;
        }
      });
    }

    setFormState(updatedFormState);
  };

  const addNewBankDetail = () => {
    setFormState([
      ...formState,
      {
        bank_title: '',
        bank_name: '',
        account_number: '',
        iban_number: '',
        is_active: false,
      },
    ]);
  };

  const removeBankDetail = (index) => {
    setFormState(formState.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const empId = mode === 'Add' ? newEmployeeData?.employee_id : employeeId;

    if (!empId) {
      toast.showToast({
        title: 'Error',
        description: 'Employee ID is missing.',
        status: 'error',
      });
      return;
    }

    if (mode === 'Add') {
      dispatch(addBankDetails(empId, formState));
    } else {
      dispatch(editBankDetails(empId, formState));
    }
  };

  if (isBankDetailsLoading) {
    return  <Loader dims='mt-[18vh] mb-[18vh] mx-auto' />
  }

  return (
    <form onSubmit={handleSubmit} className="mx-4">
      {Array.isArray(formState) && formState.map((bank, index) => (
        <Box 
          key={index} 
          position="relative"
          p={4} 
          mb={4}
        >
          {formState.length > 1 && (
            <button
              onClick={() => removeBankDetail(index)}
              className="absolute top-2 right-2 text-xl text-red-500 hover:text-red-600 active:text-red-700"
            >
              <MdDelete size={25} />
            </button>
          )}
          
          <Flex justify="space-between" align="center" mb={1}>
            <FormControl isRequired flex="1" mr={4}>
              <InputField
                type="text"
                id={`bank_title_${index}`}
                label="Title"
                placeholder="Enter title"
                value={bank.bank_title}
                onChange={(e) => handleInputChange(index, 'bank_title', e.target.value)}
                required
              />
            </FormControl>

            <FormControl isRequired flex="1" mr={4}>
              <InputField
                type="text"
                id={`bank_name_${index}`}
                label="Bank Name"
                placeholder="Enter bank name"
                value={bank.bank_name}
                onChange={(e) => handleInputChange(index, 'bank_name', e.target.value)}
                required
              />
            </FormControl>
          </Flex>

          <Flex justify="space-between" align="center" mb={1} mt={4}>
            <FormControl isRequired flex="1" mr={4}>
              <InputField
                type="text"
                id={`account_number_${index}`}
                label="Account Number"
                placeholder="Enter account number"
                value={bank.account_number}
                onChange={(e) => handleInputChange(index, 'account_number', e.target.value)}
                required
              />
            </FormControl>

            <FormControl flex="1" mr={4}>
              <InputField
                type="text"
                id={`iban_number_${index}`}
                label="IBAN Number"
                placeholder="Enter IBAN number"
                value={bank.iban_number}
                onChange={(e) => handleInputChange(index, 'iban_number', e.target.value)}
              />
            </FormControl>
          </Flex>

          <Flex justify="space-between" align="center" mb={1}>
            <FormControl className="flex flex-col mx-1 mt-3 max-w-[49%]">
              <SwitchField
                id={`is_active_${index}`}
                label="Is Active"
                value={!!bank.is_active}
                onChange={(e) => handleInputChange(index, 'is_active', e.target.checked)} 
                showLabel
              />
            </FormControl>
          </Flex>
        </Box>
      ))}

      <button
        title='add another bank'
        type="button"  
        className="float-left mb-3 mr-3 mt-2 rounded-md border-2 border-brand-500 px-4 py-1 text-base font-medium text-brand-500"
        onClick={addNewBankDetail}
      >
        <MdAdd className="inline mr-2" size={20} />
        Add Another Bank
      </button>

      <Flex justify="end" mt={4}>
        <button
          title='save the bank details'
          type="submit"
          className="float-right mb-3 mr-3 mt-2 rounded-md bg-brand-500 px-5 py-2 text-base font-medium text-white shadow-sm hover:bg-brand-600"
        >
          Save
        </button>
      </Flex>
    </form>
  );
};

export default BankDetailsForm;
