export const PAGINATION_DEFAULT_PAGE_SIZE = 50;
export const HR_APP_MAIL_NAME = "HR"
export const HR_APP_EMAIL = "hr@wecrunch.com"
export const HR_APP_URL = "https://hrapps.wecrunch.com"
export const DEV_SUPABASE_URL = "https://udijdbdvpahyuvchjvtp.supabase.co"
export const LOCAL_DEV_EMAIL = process.env.REACT_APP_DEV_EMAIL
export const LEAVE_ATTACHMENTS_STORAGE = "leave_attachments"
export const EMPLOYMENT_HISTORY_STORAGE = "employment_history"
export const LOCAL_DEV_CC = process.env.REACT_APP_DEV_CC
export const GCALENDAR_ACCESS_TOKEN_COOKIE = "hrapp_cal_access_token"
export const GCALENDAR_REFRESH_TOKEN_COOKIE = "hrapp_cal_refresh_token"