import React, { useState, useEffect } from "react";
import { FormControl, Flex } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { addNewEmployee, editEmployee } from "../api/manageEmployees";
import { EMAIL_CHECK_REGEX } from "variables/regexVars";
import customToast from "utils/toastUtils";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";
import DatePicker from "components/fields/DatePicker";
import SwitchField from "components/fields/SwitchField";
import ResumeFileUpload from 'components/fields/ResumeFileUpload';
const toast = customToast();

const validateEmail = (employee_email) =>
  EMAIL_CHECK_REGEX.test(employee_email ?? "");

const EmployeeInfoForm = ({ mode, data }) => {
  const { search, page, employeeRoles, managers, departments, sortBy } =
    useSelector((state) => state.manageEmployees);
  const dispatch = useDispatch();

  const initialFormState = {
    employee_id: data?.employee_id ?? "",
    employee_name: data?.employee_name ?? "",
    employee_email: data?.employee_email ?? "",
    date_of_joining: data?.date_of_joining
      ? new Date(data?.date_of_joining)
      : null,
    employee_role_id: data?.employee_role_id ?? "",
    manager_id: data?.manager_id ?? null,
    employee_gender: data?.employee_gender ?? "",
    department_id: data?.department_id ?? null,
    employee_status: data?.employee_status ?? true,
    last_date: data?.last_date ? new Date(data?.last_date) : null,
    employee_Nationality: data?.employee_Nationality ?? "",
    employee_Cnic: data?.employee_Cnic ?? "",
    maritial_status: data?.maritial_status ?? "",
    date_of_birth: data?.date_of_birth ? new Date(data?.date_of_birth) : null,
    employee_Religion: data?.employee_Religion ?? "",
    employee_Designation: data?.employee_Designation ?? "",
    employee_cv: data?.employee_cv ?? "",
    employee_cnic: data?.employee_cnic ?? "",
    employee_transcript: data?.employee_transcript ?? "",
  };

  const [formState, setFormState] = useState(initialFormState);

  const handleInputChange = (field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const handleCvChange = (files) => {
    console.log('file',files)
    setFormState({ ...formState, employee_cv: files });
  };

  const handleTranscriptChange = (files) => {
    console.log('file',files)
    setFormState({ ...formState, employee_transcript: files });
  };

  const handleCnicChange = (files) => {
    console.log('file',files)
    setFormState({ ...formState, employee_cnic: files });
  };

  useEffect(() => {
    setFormState({
      employee_id: data?.employee_id ?? "",
      employee_name: data?.employee_name ?? "",
      employee_email: data?.employee_email ?? "",
      date_of_joining: data?.date_of_joining
        ? new Date(data?.date_of_joining)
        : null,
      employee_role_id: data?.employee_role_id ?? "",
      manager_id: data?.manager_id ?? null,
      employee_gender: data?.employee_gender ?? "",
      department_id: data?.department_id ?? null,
      employee_status: data?.employee_status ?? true,
      last_date: data?.last_date ? new Date(data?.last_date) : null,
      employee_Nationality: data?.employee_Nationality ?? "",
      employee_Cnic: data?.employee_Cnic ?? "",
      maritial_status: data?.maritial_status ?? "",
      date_of_birth: data?.date_of_birth ? new Date(data?.date_of_birth) : null,
      employee_Religion: data?.employee_Religion ?? "",
      employee_Designation: data?.employee_Designation ?? "",
      employee_cv: data?.employee_cv ?? "",
      employee_cnic: data?.employee_cnic ?? "",
      employee_transcript: data?.employee_transcript ?? "",
    });
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = mode === "Add" ? { ...formState } : formState;

    if (!validateEmail(formData.employee_email)) {
      toast.showToast({
        title: "Invalid Data",
        description: "Please enter a valid employee email",
        status: "error",
      });
      return;
    }

    if (mode === "Edit" && formData?.employee_id) {
      dispatch(
        editEmployee(
          formData.employee_id,
          formData.manager_id,
          formData,
          search,
          page,
          sortBy
        )
      );
    } else {
      delete formData.employee_id;
      delete formData.manager_id;
      dispatch(
        addNewEmployee(formData, formState.manager_id, search, page, sortBy)
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mx-4">
      <Flex justify="space-between" align="center" mb={1}>
        <FormControl isRequired className="mx-1 mb-3 flex flex-col">
          <InputField
            type="text"
            label="Employee Name"
            placeholder="Enter employee name"
            value={formState.employee_name}
            id="employee_name"
            onChange={(e) => handleInputChange("employee_name", e.target.value)}
            required
          />
        </FormControl>
        <FormControl isRequired className="mx-1 mb-3 flex flex-col">
          <InputField
            type="email"
            label="Employee Email"
            id="employee_email"
            placeholder="Enter employee email"
            value={formState.employee_email}
            onChange={(e) =>
              handleInputChange("employee_email", e.target.value)
            }
            required
          />
        </FormControl>
      </Flex>
      <Flex justify="space-between" align="center" mb={1}>
        <FormControl isRequired className="mx-1 mb-3 flex flex-col">
          <SelectField
            id="employee_gender"
            label="Employee Gender"
            placeholder="Select Employee Gender"
            required
            value={formState.employee_gender}
            onChange={(e) =>
              handleInputChange("employee_gender", e.target.value)
            }
            options={["male", "female"].map((item) => ({
              id: item,
              value: item,
            }))}
          />
        </FormControl>
        <FormControl isRequired className="mx-1 mb-3 flex flex-col">
          <DatePicker
            placeholder="Enter employee DOJ"
            id="date_of_joining"
            label="Date of Joining"
            startDate={formState.date_of_joining}
            limitPastTo30Days={false}
            setStartDate={(e) => handleInputChange("date_of_joining", e)}
          />
        </FormControl>
      </Flex>
      <Flex justify="space-between" align="center" mb={1}>
        <FormControl isRequired className="mx-1 mb-3 flex flex-col">
          <InputField
            type="text"
            label="Employee Nationality"
            id="employee_Nationality"
            placeholder="Enter employee nationality"
            value={formState.employee_Nationality}
            onChange={(e) =>
              handleInputChange("employee_Nationality", e.target.value)
            }
            required
          />
        </FormControl>
        <FormControl isRequired className="mx-1 mb-3 flex flex-col">
          <InputField
            type="text"
            label="Employee CNIC"
            id="employee_Cnic"
            placeholder="Enter employee CNIC"
            value={formState.employee_Cnic}
            onChange={(e) => handleInputChange("employee_Cnic", e.target.value)}
            required
          />
        </FormControl>
      </Flex>
      <Flex justify="space-between" align="center" mb={1}>
        <FormControl isRequired className="mx-1 mb-3 flex flex-col">
          <SelectField
            id="maritial_status"
            label="Employee Maritial Status"
            placeholder="Select Employee Maritial Status"
            required
            value={formState.maritial_status}
            onChange={(e) =>
              handleInputChange("maritial_status", e.target.value)
            }
            options={["Married", "Single"].map((item) => ({
              id: item,
              value: item,
            }))}
          />
        </FormControl>
        <FormControl isRequired className="mx-1 mb-3 flex flex-col">
          <DatePicker
            placeholder="Enter employee DOB"
            id="date_of_birth"
            label="Date of Birth"
            startDate={formState.date_of_birth}
            limitPastTo30Days={false}
            setStartDate={(e) => handleInputChange("date_of_birth", e)}
          />
        </FormControl>
      </Flex>
      <Flex justify="space-between" align="center" mb={1}>
        <FormControl isRequired className="mx-1 mb-3 flex flex-col">
          <InputField
            type="text"
            label="Employee Religion"
            id="employee_Religion"
            placeholder="Enter employee Religion"
            value={formState.employee_Religion}
            onChange={(e) =>
              handleInputChange("employee_Religion", e.target.value)
            }
            required
          />
        </FormControl>
        <FormControl isRequired className="mx-1 mb-3 flex flex-col">
          <InputField
            type="text"
            label="Employee Designation"
            id="employee_Designation"
            placeholder="Enter employee Designation"
            value={formState.employee_Designation}
            onChange={(e) =>
              handleInputChange("employee_Designation", e.target.value)
            }
            required
          />
        </FormControl>
      </Flex>
      <Flex justify="space-between" align="center" mb={1}>
        <FormControl isRequired className="mx-1 mb-3 flex flex-col">
          <SelectField
            id="employee_role_id"
            label="Employee Role"
            placeholder="Select Employee Role"
            required
            value={formState.employee_role_id}
            onChange={(e) =>
              handleInputChange("employee_role_id", e.target.value)
            }
            options={employeeRoles.map((role) => ({
              id: role.employee_role_id,
              value: role.rolename,
            }))}
          />
        </FormControl>
        <FormControl className="mx-1 mb-3 flex flex-col">
          <SelectField
            id="department_id"
            label="Select Department"
            placeholder="Select Employee Department"
            value={formState.department_id}
            capitalize={false}
            onChange={(e) => handleInputChange("department_id", e.target.value)}
            options={departments.map((dep) => ({
              id: dep.department_id,
              value: dep.department_label,
            }))}
          />
        </FormControl>
      </Flex>
      <Flex justify="space-between" align="center" mb={1}>
        <FormControl className="mx-1 mb-3 flex max-w-[49%] flex-col">
          <SelectField
            id="manager_id"
            label="Select Manager"
            placeholder="Select Employee Manager"
            value={formState.manager_id}
            capitalize={false}
            onChange={(e) => handleInputChange("manager_id", e.target.value)}
            options={managers.map((emp) => ({
              id: emp.employee_id,
              value: emp.employee_email,
            }))}
          />
        </FormControl>
      </Flex>

      {mode === "Edit" && (
        <Flex justify="space-between" align="center" mb={1}>
          <FormControl className="mx-1 mb-3 flex max-w-[49%] flex-col">
            <DatePicker
              placeholder="Enter last working date"
              id="last_date"
              label="Last Working Date"
              startDate={formState.last_date}
              limitPastTo30Days={false}
              setStartDate={(e) => handleInputChange("last_date", e)}
              includeWeekends
            />
          </FormControl>
          <FormControl className="mx-1 mb-3 flex max-w-[49%] flex-col">
            <SwitchField
              id="employee_status"
              label="Employee Status"
              value={formState.employee_status}
              onChange={(e) =>
                handleInputChange("employee_status", e.target.checked)
              }
            />
          </FormControl>
        </Flex>
      )}
      <Flex wrap="wrap" spacing={6} mb={4} alignItems="center">
        <FormControl flex="1" mr={4} mb={4}>
          <ResumeFileUpload
            size="sm"
            label="Upload Your Latest Resume/Cv"
            id="employee_cv"
            files={formState.employee_cv ? formState.employee_cv : ''}
            setFiles={handleCvChange}
          />
        </FormControl>
        <FormControl flex="1" mr={4} mb={4}>
          <ResumeFileUpload
            size="sm"
            label="Upload Your Transcript"
            id="employee_transcript"
            files={formState.employee_transcript ? formState.employee_transcript : ''}
            setFiles={handleTranscriptChange}
          />
        </FormControl>
        <FormControl flex="1" mb={4}>
          <ResumeFileUpload
            size="sm"
            label="Upload Your CNIC F/B"
            id="employee_cnic"
            files={formState.employee_cnic ? formState.employee_cnic : ''}
            setFiles={handleCnicChange}
          />
        </FormControl>
      </Flex>
      <button
        title="save the employee info"
        type="submit"
        className="float-right mb-3 mr-3 mt-2 rounded-md bg-brand-500 px-5 py-2 text-base font-medium text-white shadow-sm hover:bg-brand-600"
      >
        Save
      </button>
    </form>
  );
};

export default EmployeeInfoForm;
