import axios from "axios";
import customToast from "./toastUtils";

const toast = customToast();
export const sendEmail = async (to, subject, body, cc = [], bcc = []) => {
  try {
    const payload = {
      to,
      cc,
      bcc,
      subject,
      body,
    };

    console.log("Preparing to send email with payload:", payload);

    const response = await axios.post(
      `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/send-email`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          'apikey': process.env.REACT_APP_SUPABASE_KEY,
          'Authorization': `Bearer ${process.env.REACT_APP_SUPABASE_KEY}`,
        },
      }
    );

    if (response.status === 200) {
      console.log(`--------- Email sent to ${to.map(recipient => recipient.email).join(", ")}`);
      return true;
    }
  } catch (error) {
    console.error('Error sending email:', error);
    toast.showToast({
      title: 'Email not sent',
      description: `Could not send email notification to ${to.map(recipient => recipient.email).join(", ")}`,
      status: 'warning',
    });
    return false;
  }
};

