import customToast from 'utils/toastUtils';
import { supabase } from 'utils/supabase';
import { setHolidays, setPaginationData, closeDeleteConfirmation, setIsLoading } from '../reducers/manageAddHolidayReducers';
import { PAGINATION_DEFAULT_PAGE_SIZE } from 'variables/common';

const toast = customToast();
const pageSize = PAGINATION_DEFAULT_PAGE_SIZE

export const getAllHolidays = (search, page, sortBy, filterYear) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));

    // Base query builder
    const year = filterYear ?? new Date().getFullYear();
    const baseQuery = supabase
      .from('public_holidays')
      .select('*')
      .gte('start_date', `${year}-01-01`)
      .lt('start_date', `${Number(year) + 1}-01-01`)
      .order(sortBy[0].id, { ascending: !sortBy[0].desc });

    // Add search condition if provided
    if (search?.length > 0) {
      baseQuery.ilike('holiday_name', `%${search}%`);
    }

    // Get paginated data
    const response = await baseQuery
      .range((page - 1) * pageSize, (pageSize * page) - 1);

    // Get total count
    const { count, error: countError } = await baseQuery
      .select('*', { count: 'exact', head: true });

    // Handle count error
    if (countError) {
      toast.showToast({
        title: 'Count error.',
        description: countError.message || 'Could not get total count of records.',
        status: 'error',
      });
    }

    // Handle response error
    if (response.error) {
      throw new Error(response.error.message || 'Holiday data could not be fetched.');
    }

    // Update store with results
    const { data } = response;
    dispatch(setHolidays(data || []));
    dispatch(setPaginationData({ total: count ?? data?.length ?? 0 }));

  } catch (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: error.message || 'Holiday data could not be fetched.',
      status: 'error',
    });
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const addNewHoliday = (holidayData, search, page, sortBy, filterYear) => async (dispatch) => {
  try {
    holidayData.start_date = holidayData.start_date.toLocaleDateString("en-US");
    holidayData.end_date = holidayData.end_date ? holidayData.end_date.toLocaleDateString("en-US") : holidayData.start_date;
    const { data, error } = await supabase
      .from('public_holidays')
      .insert([holidayData])
      .select();

    if (error) {
      toast.showToast({
        title: 'An error occurred.',
        description: error.message || 'Unable to add holiday.',
        status: 'error',
      });
    } else if (data && data.length > 0) {
      dispatch(getAllHolidays(search, page, sortBy, filterYear));
      toast.showToast({
        title: 'Added.',
        description: 'Holiday added successfully',
        status: 'success',
      });
    }
  } catch (error) {
    console.error('Add holiday error:', error.message);
    toast.showToast({
      title: 'An error occurred.',
      description: 'Holiday data could not be added.',
      status: 'error',
    });
  }
};

export const editHoliday = (holidayData, search, page, sortBy, filterYear) => async (dispatch) => {
  try {
    holidayData.start_date = holidayData.start_date.toLocaleDateString("en-US");
    holidayData.end_date = holidayData.end_date ? holidayData.end_date.toLocaleDateString("en-US") : holidayData.start_date;
    const { data, error } = await supabase
      .from('public_holidays')
      .update(holidayData)
      .eq('public_holiday_id', holidayData.public_holiday_id)
      .select();

    if (error) {
      toast.showToast({
        title: 'An error occurred.',
        description: error.message || 'Unable to update holiday.',
        status: 'error',
      });
    } else if (data && data.length > 0) {
      dispatch(getAllHolidays(search, page, sortBy, filterYear  ));
      toast.showToast({
        title: 'Updated.',
        description: 'Holiday updated successfully',
        status: 'success',
      });
    }
  } catch (error) {
    // console.error('Edit holiday error:', error.message);
    toast.showToast({
      title: 'An error occurred.',
      description: 'Holiday data could not be updated.',
      status: 'error',
    });
  }
};

export const deleteHoliday = (holidayId, search, page, sortBy, filterYear) => async (dispatch) => {
  try {
    const { error } = await supabase
      .from('public_holidays')
      .delete()
      .eq('public_holiday_id', holidayId);

    if (error) {
      toast.showToast({
        title: 'An error occurred.',
        description: error.message || 'Unable to delete holiday.',
        status: 'error',
      });
    } else {
      // Dispatch the action to fetch all holidays again after successful deletion
      dispatch(getAllHolidays(search, page, sortBy, filterYear ));

      // Dispatch the action to close the delete confirmation dialog
      dispatch(closeDeleteConfirmation());

      toast.showToast({
        title: 'Deleted.',
        description: 'Holiday deleted successfully',
        status: 'success',
      });
    }
  } catch (error) {
    // console.error('Delete holiday error:', error.message);
    toast.showToast({
      title: 'An error occurred.',
      description: 'Holiday data could not be deleted.',
      status: 'error',
    });
  }
};

export const fetchHolidaysList = async () => {
  let holidays = []
  try {
    const { data, error } = await supabase.from('public_holidays').select('start_date, end_date');
    if (error) {
      throw error;
    }
    // Extract and format dates from the fetched data
    const holidayDates = data.reduce((acc, holiday) => {
      const startDate = new Date(holiday.start_date);
      const endDate = new Date(holiday.end_date);
      // Create an array of dates between start_date and end_date
      const dateRange = [];
      let currentDate = startDate;
      while (currentDate <= endDate) {
        dateRange.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return [...acc, ...dateRange];
    }, []);
    holidays = holidayDates.map(date => date.toLocaleDateString("en-US"));
  } catch (error) {
    console.error('Error fetching holidays:', error.message);
    toast.showToast({
      title: 'An error occurred.',
      description: 'Holidays data could not be added.',
      status: 'error',
    });
  }
  return holidays
};