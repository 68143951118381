import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  leavesQuota: [],
  search: '',
  page: 1,
  isLoading: false,
  pagination: {
    total: 0,
    previous: null,
    next: null
  },
  sortBy: [{ id: 'employee_name', desc: false }],
  alphaFilter: 'A',
  leaveType: '',
  leaveCount: '',
  selectedEmployees: [],
  reasonNote: '',
  leaveYear: new Date().getFullYear(),
  filterYear: new Date().getFullYear(),
  isFormOpen: false,  // Added to manage the form visibility
};

const leavesQuotaSlice = createSlice({
  name: 'leavesQuota',
  initialState,
  reducers: {
    setLeavesQuota: (state, action) => {
      state.leavesQuota = action.payload;
    },
    setPage: (state, action) => {
      state.page = Number(action.payload)
    },
    setPaginationData: (state, action) => {
      state.pagination = action.payload
    },
    setSearch: (state, action) => {
      if (action.payload && state.search !== action.payload) state.page = 1;
      state.search = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setSort: (state, action) => {
      state.sortBy = action.payload;
    },
    setAlphaFilter: (state, action) => {
      state.alphaFilter = action.payload;
    },
    setLeaveType: (state, action) => { 
      state.leaveType = action.payload; 
    },
    setLeaveCount: (state, action) => { 
      state.leaveCount = action.payload; 
    },
    setSelectedEmployees: (state, action) => { 
      state.selectedEmployees = action.payload; 
    },
    setReasonNote: (state, action) => { 
      state.reasonNote = action.payload; 
    },
    setLeaveYear: (state, action) => { 
      state.leaveYear = action.payload; 
    },
    openForm: (state) => {  // Open form action
      state.isFormOpen = true;
      state.leaveType = '';
      state.leaveCount = '';
      state.selectedEmployees = [];
      state.reasonNote = '';
      state.leaveYear = new Date().getFullYear();
    },
    closeForm: (state) => {  // Close form action
      state.isFormOpen = false;
    },
    setFilterYear: (state, action) => {
      state.filterYear = action.payload;
    }
  },
});

export const { 
  setLeavesQuota, setPage, setPaginationData, setSearch, setIsLoading, setSort,
  setAlphaFilter, setLeaveType, setLeaveCount, setSelectedEmployees, setReasonNote,
  setLeaveYear, openForm, closeForm, setFilterYear
} = leavesQuotaSlice.actions;

export default leavesQuotaSlice.reducer;
