import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Card } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchAllLeaveRequests } from '../manageLeaves/api/manageLeaves';
import { setCalendarEvents, setHolidaysList, setIsLoading, setRange } from './reducers/leavesCalendarReducers';
import { getCalendarHolidays } from './api/leavesCalendar';
import Loader from 'components/loader/miniLoader';
import LeaveDetailsPopup from '../manageLeaves/components/LeaveDetailsPopup';

const localizer = momentLocalizer(moment)

const CalendarView = () => {
  const {
    calendarData,
    holidays,
    isLoading,
    range,
  } = useSelector((state) => state.leavesCalendar);
  const { darkMode, sideBarOpen } = useSelector((state) => state.layout);
  const dispatch = useDispatch();
  const [agendaView, setAgendaView] = useState(false);
  const [updateCells, setUpdateCells] = useState(0);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const updateTimeCells = () => {
      const timeCells = document.querySelectorAll('.rbc-agenda-time-cell span.rbc-continues-after');
      // set half day in agenda view time slot
      timeCells.forEach(cell => {
        if (cell.textContent.trim().includes('12:00 AM')) {
          cell.textContent = 'half day';
        }
      });
    };

    if (agendaView && updateCells > 0) updateTimeCells();
  }, [agendaView, updateCells]);

  useEffect(() => {
    dispatch(setIsLoading(true));
    getCalendarHolidays().then((data) => {
      dispatch(
        setHolidaysList(
          data.map((ev) => ({
            start: ev.start_date,
            end: ev.end_date ? ev.end_date : ev.start_date,
            title: ev.holiday_name,
            allDay: true,
            color: 'green',
          }))
        )
      );
      dispatch(setIsLoading(false));
    });
  }, [dispatch]);


  // Fetch events whenever the range changes
  useEffect(() => {
    dispatch(setIsLoading(true));
    fetchAllLeaveRequests({
      start: moment(range.start).format('YYYY-MM-DD'),
      end: moment(range.end).format('YYYY-MM-DD'),
    }).then(({ data }) => {
      dispatch(
        setCalendarEvents(
          data.filter((lv) => lv.leave_type_name !== 'maternity').map((ev) => ({
            start: ev.start_date,
            end: ev.end_date ? ev.end_date : ev.start_date,
            title: `${ev.employee_name} - ${ev.leave_type_name === 'special'
              ? ''
              : ev.is_half_day
                ? `half day ${ev.half_leave_type ? `(${ev.half_leave_type})` : ''}`
                : ev.leave_type_name
              } leave`,
            allDay: !ev.is_half_day,
            isSecondHalf: ev.half_leave_type && ev.half_leave_type === 'second-half',
            substitute: { overall_esc: ev.overall_escalation?.name, details: ev.substitutions ?? [] }
          }))
        )
      );
      dispatch(setIsLoading(false));
    });
  }, [dispatch, range]);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
  };

  return (
    <>
      {isLoading &&
        <Loader dims="absolute top-1/2 z-10 left-20" height={5} width={5} />}
      <div className="my-6 mx-4">
        <Card
          className={`bg-white dark:!bg-navy-800 p-8 dark:shadow-none dark:text-lightPrimary ${isLoading ? 'opacity-60' : ''}`}
          style={{ maxWidth: sideBarOpen ? '77vw' : '93vw' }}
        >
          <Calendar
            localizer={localizer}
            events={[
              ...calendarData.map((ev) => ({
                ...ev,
                start: moment(ev.start, 'YYYY-MM-DD').toDate(),
                end: moment(ev.end, 'YYYY-MM-DD').add(1, 'days').toDate(),
              })),
              ...holidays.map((ev) => ({
                ...ev,
                start: moment(ev.start, 'YYYY-MM-DD').toDate(),
                end: moment(ev.end, 'YYYY-MM-DD').add(1, 'days').toDate(),
              })),
            ]}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 550 }}
            dayPropGetter={(date) => {
              const day = new Date(date).getDay();
              const today = new Date();
              if (date.toDateString() === today.toDateString() && darkMode)
                return { style: { backgroundColor: 'black' } };
              if (day === 0 || day === 6)
                return {
                  style: {
                    backgroundColor: darkMode ? '#303030' : '#e6e6e6',
                  },
                };
            }}
            formats={{
              monthHeaderFormat: 'MMMM YYYY',
              agendaHeaderFormat: (range) =>
                `${moment(range.start).format('DD-MMMM-YY')} to ${moment(range.end).format('DD-MMMM-YY')}`,
              agendaDateFormat: 'ddd DD-MMM-YY',
            }}
            views={['month', 'work_week', 'day', 'agenda']}
            eventPropGetter={(ev) => {
              let bgColor = ev.color ? ev.color : darkMode ? '#F28820' : '#f5ac63';
              bgColor = ev.allDay ? bgColor : '#2dabe9';
              let styleObj = { backgroundColor: bgColor };
              if (!ev.allDay) {
                styleObj = { ...styleObj, width: '50%' };
              }
              if (ev.isSecondHalf) {
                styleObj = { ...styleObj, marginLeft: 'auto' };
              }
              return { style: styleObj };
            }}
            onNavigate={(date, view) => {
              if (agendaView) setUpdateCells(updateCells + 1);
              if (view === 'month') {
                const start = moment(date).startOf('month').format('YYYY-MM-DD');
                const end = moment(date).endOf('month').format('YYYY-MM-DD');
                dispatch(setRange({ start, end }));
              }
            }}
            onView={(v) => {
              if (v === 'agenda') {
                setAgendaView(true);
                setUpdateCells(1);
              } else {
                setAgendaView(false);
                setUpdateCells(0);
              }
            }}
            onSelectEvent={handleEventClick}
            className={`${darkMode ? 'dark' : 'light'}-custom-calendar`}
          />
        </Card>
      </div>
      {selectedEvent && selectedEvent.substitute.details?.length && (
        <LeaveDetailsPopup
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          title={selectedEvent.title}
          substitute={selectedEvent.substitute} />
      )}
    </>
  );
};

export default CalendarView;
