import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from '@chakra-ui/react';
import { applyTagStyles } from "variables/regexVars";
import { LEAVE_ATTACHMENTS_STORAGE } from "variables/common";
import { MdOpenInNew } from 'react-icons/md';
import { downloadAttachmentsFromStorage } from 'views/user/myLeaves/api/myLeaves';

const LeaveDetailsPopup = ({
  isModalOpen, handleCloseModal, title, substitute, leaveReason, files
}) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-header"
      aria-describedby="modal-body"
    >
      <ModalOverlay />
      <ModalContent
        role="dialog"
        aria-labelledby="modal-header"
        aria-describedby="modal-body"
        position="fixed"
        top="30%"
        transform="translate(-50%, -50%)"
        maxWidth="500px"
        width="100%"
      >
        <ModalHeader id="modal-header" className="p-2 rounded-t-md">
          <p>{title}</p>
        </ModalHeader>
        <ModalCloseButton aria-label="Close Modal" className="font-bold" />
        <ModalBody id="modal-body" className="p-6 bg-gray rounded-b-sm">
          {leaveReason && (<p style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: applyTagStyles(leaveReason ?? "") }} />)}
          {files && files?.length > 0 && (
            <>
              <h3 className="my-2 text-sm font-medium text-gray-600">Attachments</h3>
              {files.map((file, index) => {
                return (
                  <p className="mt-2 text-sm font-medium px-2 py-1 rounded-md bg-gray-600 flex gap-4 items-center justify-between">
                    <span className="cursor-default">{file?.startsWith(`${LEAVE_ATTACHMENTS_STORAGE}/`) ? file.split('/').slice(-1) : file}</span>
                    <MdOpenInNew className="cursor-pointer" onClick={() => downloadAttachmentsFromStorage(file)} fontSize={18} />
                  </p>
                )
              })}
            </>
          )}
          {substitute.details.length ? (
            <>
              <p className="text-md font-bold mb-4">Substitution Details:</p>
              <table className="w-full table-auto border-collapse">
                <thead>
                  <tr className="bg-navy-200 text-left text-navy-600">
                    <th className="p-2 border-2 border-navy-500">Project</th>
                    <th className="p-2 border-2 border-navy-500">Substitute</th>
                  </tr>
                </thead>
                <tbody>
                  {substitute.details
                    .map((detail, index) => {
                      const { project_name, substitute_name } = detail;
                      return (
                        <tr key={index} className="even:bg-gray-50 odd:bg-white">
                          <td className="p-2 border-2 border-navy-500">{project_name || 'N/A'}</td>
                          <td className="p-2 border-2 border-navy-500">{substitute_name || 'No Substitute'}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              <p className="mt-6 text-md font-semibold">
                Overall Escalation: <span className="font-normal">{substitute.overall_esc || 'Not Assigned'}</span>
              </p>
            </>
          ) : (
            <p className="text-center text-red-500 font-semibold my-4">No substitution info added</p>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default LeaveDetailsPopup;