import React, { useState } from "react";
import InputField from "./InputField";
import SelectField from "./SelectField";
import SwitchField from "./SwitchField";
import { Flex, FormControl } from "@chakra-ui/react";
import { MdRefresh } from "react-icons/md";

const FilterOverlay = ({ departments, managers, employeeRoles, onFilterSubmit, onReset, formState }) => {
  const [localFormState, setLocalFormState] = useState(formState || {
    name: '',
    department_id: '',
    manager_id: '',
    employee_status: true,
    employee_role_id: '',
    employee_city: '',
  });

  const handleInputChange = (field, value) => {
    if (field === 'employee_status') {
      value = Boolean(value);
    }
    setLocalFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onFilterSubmit(localFormState); // Submit the current filter state
  };

  const handleReset = () => {
    setLocalFormState({
      name: '',
      department_id: '',
      manager_id: '',
      employee_status: true,
      employee_role_id: '',
      employee_city: '',
    });
    onReset();
  };

  return (
    <div
      className="bg-white dark:bg-navy-700 bg-opacity-50 p-4 mr-8 ml-6 mb-3 rounded-md shadow-md backdrop-blur transform transition-transform duration-300 ease-out opacity-100"
    >
      <div className="mb-4">
        <form onSubmit={handleSubmit} className="mx-4">
          <Flex justify="space-between" align="center" mb={4}>
            <FormControl className="mx-1 flex flex-col w-[32%]">
              <InputField
                type="text"
                placeholder="Enter name"
                value={localFormState.name}
                onChange={(e) => handleInputChange('name', e.target.value)}
              />
            </FormControl>

            <FormControl className="mx-1 flex flex-col w-[32%]">
              <SelectField
                id="department_id"
                placeholder="Select Department"
                value={localFormState.department_id}
                capitalize={false}
                onChange={(e) => handleInputChange("department_id", e.target.value)}
                options={departments.map((dep) => ({
                  id: dep.department_id,
                  value: dep.department_label,
                }))}
              />
            </FormControl>

            <FormControl className="mx-1 flex flex-col w-[32%]">
              <InputField
                type="text"
                id="employee_city"
                placeholder="Enter City"
                value={localFormState.employee_city}
                onChange={(e) => handleInputChange("employee_city", e.target.value)}
              />
            </FormControl>
          </Flex>

          <Flex justify="space-between" align="center" mb={4}>
            <FormControl className="mx-1 flex flex-col w-[32%]">
              <SelectField
                id="employee_role_id"
                placeholder="Select Employee Role"
                value={localFormState.employee_role_id}
                onChange={(e) => handleInputChange("employee_role_id", e.target.value)}
                options={employeeRoles.map((role) => ({
                  id: role.employee_role_id,
                  value: role.rolename,
                }))}
              />
            </FormControl>

            <FormControl className="mx-1 flex flex-col w-[32%]">
              <SelectField
                id="manager_id"
                placeholder="Select Manager"
                value={localFormState.manager_id}
                capitalize={false}
                onChange={(e) => handleInputChange("manager_id", e.target.value)}
                options={managers.map((emp) => ({
                  id: emp.employee_id,
                  value: emp.employee_email,
                }))}
              />
            </FormControl>

            <FormControl className="mx-1 flex flex-col w-[32%]">
              <SwitchField
                id="employee_status"
                label="Status"
                value={localFormState.employee_status}
                onChange={(e) => handleInputChange("employee_status", e.target.checked)}
              />
            </FormControl>
          </Flex>
        </form>
      </div>

      <div className="flex justify-end space-x-4 mt-4">
        <button
          title="reset the filter"
          onClick={handleReset}
          className="flex items-center mb-3 mt-2 rounded-md border-2 border-brand-500 px-4 py-1 text-base font-medium text-brand-500 hover:bg-brand-200 hover:text-white"
        >
          <MdRefresh size={20} />
        </button>

        <button
          title="apply the filter"
          onClick={handleSubmit}
          className="mb-3 mr-3 mt-2 rounded-md bg-brand-500 px-5 py-2 text-base font-medium text-white shadow-sm hover:bg-brand-600"
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default FilterOverlay;
