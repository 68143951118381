import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  employmentHistory: {
    total_experience: '',
    companyHistories: [],
  },
  isEmploymentHistoryLoading: false,
};

const manageEmployeesHistoryReducers = createSlice({
  name: 'manageEmployeeHistory',
  initialState,
  reducers: {
    setEmploymentHistory: (state, action) => {
      state.employmentHistory = action.payload;
    },

    updateEmploymentHistoryField: (state, action) => {
      const { field, value } = action.payload;
      state.employmentHistory[field] = value;
    },

    updateCompanyHistoryField: (state, action) => {
      const { index, field, value } = action.payload;
      state.employmentHistory.companyHistories[index][field] = value;
    },

    addCompanyHistory: (state) => {
      state.employmentHistory.companyHistories.push({
        company_name: '',
        designation: '',
        experience_letter: null,
      });
    },

    removeCompanyHistory: (state, action) => {
      state.employmentHistory.companyHistories.splice(action.payload, 1);
    },

    setIsEmploymentHistoryLoading: (state, action) => {
      state.isEmploymentHistoryLoading = action.payload;
    },
  },
});

export const {
  setEmploymentHistory,
  updateEmploymentHistoryField,
  updateCompanyHistoryField,
  addCompanyHistory,
  removeCompanyHistory,
  setIsEmploymentHistoryLoading,

} = manageEmployeesHistoryReducers.actions;

export default manageEmployeesHistoryReducers.reducer;
