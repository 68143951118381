import { setLeavesQuota, setPaginationData, setIsLoading } from "../reducers/leavesQuotaReducers";
import customToast from 'utils/toastUtils';
import { supabase } from 'utils/supabase';
import { PAGINATION_DEFAULT_PAGE_SIZE } from 'variables/common';

const toast = customToast();
const pageSize = PAGINATION_DEFAULT_PAGE_SIZE

const getAllLeavesQuotaData = async (search, page, sortBy, alphaFilter, filterYear) => {
  try {
    let query = supabase.from('employee_leave_summary').select('*');
    if (search && search.length > 0) {
      query = query.ilike('employee_name', `%${search}%`);
    } else if (alphaFilter !== 'All') {
      query = query.ilike('employee_name', `${alphaFilter}%`);
    }
    if (filterYear) {
      query = query.eq('leave_year', filterYear ?? new Date().getFullYear());
    }
    query = query
      .order(sortBy[0].id, { ascending: !sortBy[0].desc })
      .range((page - 1) * pageSize, (pageSize * page) - 1);

    const { data, error } = await query;
    if (error) {
      toast.showToast({
        title: 'An error occurred.',
        description: error?.message || 'Leaves quota could not be fetched.',
        status: 'error',
      });
      return { data: [], count: 0 };
    }

    let countQuery = supabase.from('employee_leave_summary').select('*', { count: 'exact', head: true });

    if (search && search.length > 0) {
      countQuery = countQuery.ilike('employee_name', `%${search}%`);
    } else if (alphaFilter !== 'All') {
      countQuery = countQuery.ilike('employee_name', `${alphaFilter}%`);
    }

    const { count, err } = await countQuery;
    if (err) {
      toast.showToast({
        title: 'Count error.',
        description: err?.message || 'Could not get total count of records.',
        status: 'error',
      });
      return { data: data ?? [], count: 0 };
    }

    return { data: data ?? [], count: count ?? data.length };

  } catch (err) {
    toast.showToast({
      title: 'Unexpected error.',
      description: 'An unexpected error occurred while fetching leaves quota data.',
      status: 'error',
    });
    return { data: [], count: 0 };
  }
};


// const getLeavesQuotaDataForRepManager = async (employee_id, search, page) => {
//   let emplIds = [employee_id]
//   const { data: managedEmpl } = await supabase.from('employee_manager')
//     .select("employee_id").eq('manager_id', employee_id)
//   if (managedEmpl && managedEmpl?.length > 0) {
//     emplIds.push(managedEmpl.map(emp => emp.employee_id))
//   }
//   let { data, error } = search && search?.length > 0 ? await supabase
//     .from('employee_leave_summary')
//     .select('*').in('employee_id', emplIds).ilike('employee_name', `%${search}%`)
//     .range((page - 1) * pageSize, (pageSize * page) - 1) : await supabase
//       .from('employee_leave_summary')
//       .select('*').in('employee_id', emplIds).range((page - 1) * pageSize, (pageSize * page) - 1)
//   const { count, err } = search && search?.length > 0 ? await supabase
//     .from('employee_leave_summary')
//     .select('*', { count: 'exact', head: true })
//     .in('employee_id', emplIds)
//     .ilike('employee_name', `%${search}%`) :
//     await supabase
//       .from('employee_leave_summary')
//       .select('*', { count: 'exact', head: true })
//       .in('employee_id', emplIds)
//   if (err) toast.showToast({
//     title: 'Count error.',
//     description: err?.message && typeof err?.message === 'string' ? err?.message : 'Could not get total count of records.',
//     status: 'error',
//   })
//   if (error) toast.showToast({
//     title: 'An error occurred.',
//     description: error?.message && typeof error?.message === 'string' ? error?.message : 'Leave data could not be fetched.',
//     status: 'error',
//   })
//   return { data: data ?? [], count: count ?? data?.length }
// }

export const getAllLeavesQuota = (employee, search, page, sortBy, alphaFilter, filterYear) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true))
    // const { data, count } = employee?.employee_role_name === "hr-manager" ? await getAllLeavesQuotaData(search, page) :
    //   await getLeavesQuotaDataForRepManager(employee.employee_id, search, page)
    const { data, count } = await getAllLeavesQuotaData(search, page, sortBy, alphaFilter, filterYear)
    if (data) {
      dispatch(setLeavesQuota(data ?? []));
      dispatch(setPaginationData({
        total: count ?? data.length,
      }))
    }
    dispatch(setIsLoading(false))
  } catch (error) {
    console.log({ error })
    toast.showToast({
      title: 'An error occurred.',
      description: 'Data could not be fetched.',
      status: 'error',
    })
  }
};

export const fetchall = async (filterYear) => {
  try {
    const { data, error } = await supabase
      .from('employee_leave_summary')
      .select('*')
      .eq('leave_year', filterYear);

    if (error) {
      throw error;
    } 
    return { data: data ?? [] };
  } catch (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: error?.message && typeof error?.message === 'string' ? error?.message : 'Leaves quota could not be fetched.',
      status: 'error',
    });
    return { data: [] };
  }
};

export const submitAdditionalLeave = async (formData) => {
  try {
    const { data, error } = await supabase
      .from('additional_leaves')
      .insert(formData.selectedEmployees.map(emp => ({
        employee_id: emp,
        leave_type_id: formData.leaveType,
        leaves_count: formData.leaveCount,
        leave_year: formData.leaveYear,
        reason_note: formData.reasonNote || null,
      })));

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error submitting additional leaves:', error);
    toast.showToast({
      title: 'An error occurred.',
      description: error?.message || 'Error submitting additional leaves.',
      status: 'error',
    });
  }
};


export const getLeaveTypes = async () => {
  try {
    const { data, error } = await supabase
      .from('leave_types')
      .select('leave_type_id, leave_type_name')
      .eq('leave_year', new Date().getFullYear()) // current year
      .order('display_order');

    if (error) {
      toast.showToast({
        title: 'An error occurred.',
        description: error?.message || 'Error fetching leave types.',
        status: 'error',
      });
    }
    
    return data.map((leaveType) => ({
      id: leaveType.leave_type_id,
      value: leaveType.leave_type_name,
    }));
  } catch (err) {
    console.error(err.message);
    return [];
  }
};