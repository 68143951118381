import React from 'react';

const AlphabetFilter = (props) => {
  const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  const { value, handleAlphabetClick } = props

  return (
    <div className="flex justify-center w-full">
      
      {alphabets.map((alphabet) => (
        <button
          title={`Filter by ${alphabet}`}
          key={alphabet}
          className={`px-3 py-1 text-sm hover:bg-brand-500 hover:text-white ${value===alphabet ? 'bg-brand-600 text-white rounded' : 'bg-lightPrimary text-navy-700 dark:bg-navy-400 dark:text-white'} active:bg-brand-500 active:text-white`}
          onClick={() => handleAlphabetClick(alphabet)}
        >
          {alphabet}
        </button>
      ))}
      <button
        title='show all records'
        className={`px-3 py-1 text-sm hover:bg-brand-500 hover:text-white ${value === 'All' ? 'bg-brand-600 text-white rounded' : 'bg-lightPrimary text-navy-700 dark:bg-navy-400 dark:text-white'} active:bg-brand-500 active:text-white`}
        onClick={() => handleAlphabetClick('All')}
      >
        All
      </button>
    </div>
  );
};

export default AlphabetFilter;
