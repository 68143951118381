import { closeDeleteConfirmation, setPaginationData, setEmployees, setEmployeeRoles, setManagers, setIsLoading, setDepartments, setNewEmployeeData } from '../reducers/manageEmployeesReducers';
import customToast from 'utils/toastUtils';
import { supabase } from 'utils/supabase';
import { setLoggedInEmployee } from 'layouts/reducers/authReducers';
import { PAGINATION_DEFAULT_PAGE_SIZE } from 'variables/common';
import { EMPLOYMENT_HISTORY_STORAGE } from 'variables/common';

const toast = customToast();
const pageSize = PAGINATION_DEFAULT_PAGE_SIZE

export const getAllEmployeeRoles = () => async (dispatch) => {
  const { data, error } = await supabase
    .from('employee_roles').select('*')
  if (data) dispatch(setEmployeeRoles(data));
  if (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: 'Data could not be fetched.',
      status: 'error',
    })
  }
};

export const getAllDepartments = () => async (dispatch) => {
  const { data, error } = await supabase
    .from('departments').select('*')
  if (data) dispatch(setDepartments(data));
  if (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: 'Data could not be fetched.',
      status: 'error',
    })
  }
};

export const getEmployeeManagers = () => async (dispatch) => {
  let { data: managers, error } = await supabase
    .from('employees_view')
    .select('employee_id, employee_email, employee_name')
    .in('employee_role_name', ['reporting-manager', 'top-management', 'hr-manager'])
  if (managers) dispatch(setManagers(managers))
  if (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: 'Data could not be fetched.',
      status: 'error',
    })
  }
};

export const fetchEmployeeByEmail = (email) => async (dispatch) => {
  let employee = null;
  try {
    let { data, error } = await supabase
      .from('employees_view').select('*').eq('employee_email', email)
    if (error) toast.showToast({
      title: 'An error occurred.',
      description: error?.message && typeof error?.message === 'string' ? error?.message : 'Employee data could not be fetched.',
      status: 'error',
    })
    else if (data && data?.length > 0) {
      employee = data[0]
      dispatch(setLoggedInEmployee(data[0]));
    }
  } catch (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: 'Employee data could not be fetched.',
      status: 'error',
    })
  }
  return employee
};

export const getAllEmployees = (search, page, sortBy, filters) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    let query = supabase
      .from('employees_view')
      .select('*')
      .order(sortBy[0].id, { ascending: !sortBy[0].desc })
      .range((page - 1) * pageSize, (page * pageSize) - 1);
    
    if (search && search?.length > 0) {
      query = query.or(
        `employee_name.ilike.%${search}%, employee_email.ilike.%${search}%, manager_name.ilike.%${search}%, department_label.ilike.%${search}%`
      );
    }
    if (filters?.name && filters.name.trim() !== '') {
      query = query.ilike('employee_name', `%${filters.name}%`);
    }

    if (filters?.department_id && filters.department_id !== '') {
      query = query.eq('department_id', filters.department_id);
    }

    if (typeof filters.employee_status === 'boolean') {
      query = query.eq('employee_status', filters.employee_status);
    }

    if (filters?.manager_id && filters.manager_id !== '') {
      query = query.eq('manager_id', filters.manager_id);
    }

    if (filters?.employee_role_id && filters.employee_role_id !== '') {
      query = query.eq('employee_role_id', filters.employee_role_id);
    }

    if (filters?.employee_city && filters.employee_city !== '') {
      query = query.eq('employee_city', filters.employee_city);
    }

    const { data, error } = await query;
    let countQuery = supabase
      .from('employees_view')
      .select('*', { count: 'exact', head: true })
    if (search && search.length > 0) {
      countQuery = countQuery.or(
        `employee_name.ilike.%${search}%, employee_email.ilike.%${search}%, manager_name.ilike.%${search}%, department_label.ilike.%${search}%`
      );
    }

    if (filters?.name && filters.name.trim() !== '') {
      countQuery = countQuery.ilike('employee_name', `%${filters.name}%`);
    }

    if (filters?.department_id && filters.department_id !== '') {
      countQuery = countQuery.eq('department_id', filters.department_id);
    }

    if (typeof filters.employee_status === 'boolean') {
      countQuery = countQuery.eq('employee_status', filters.employee_status);
    }

    if (filters?.manager_id && filters.manager_id !== '') {
      countQuery = countQuery.eq('manager_id', filters.manager_id);
    }

    if (filters?.employee_role_id && filters.employee_role_id !== '') {
      countQuery = countQuery.eq('employee_role_id', filters.employee_role_id);
    }

    if (filters?.employee_city && filters.employee_city !== '') {
      countQuery = countQuery.eq('employee_city', filters.employee_city);
    }
    const { count, err } = await countQuery;
    if (err) {
      toast.showToast({
        title: 'Count error.',
        description: err?.message && typeof err?.message === 'string' ? err?.message : 'Could not get total count of records.',
        status: 'error',
      });
    }
    if (error) {
      toast.showToast({
        title: 'An error occurred.',
        description: error?.message && typeof error?.message === 'string' ? error?.message : 'Employees data could not be fetched.',
        status: 'error',
      });
    } else if (data) {
      dispatch(setEmployees(data ?? []));
      dispatch(setPaginationData({
        total: count ?? data?.length,
        previous: page > 1 ? page - 1 : null,
        next: (page * pageSize) < (count ?? data?.length) ? page + 1 : null,
      }));
    }

    dispatch(setIsLoading(false));
  } catch (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: 'Data could not be fetched.',
      status: 'error',
    });
    dispatch(setIsLoading(false));
  }
};

export const addNewEmployee = (employeeData, managerId, search, page, sortBy) => async (dispatch) => {
  try {
    employeeData.date_of_joining = employeeData.date_of_joining.toLocaleDateString("en-US");
    const { data, error } = await supabase
      .from('employee')
      .insert([employeeData])
      .select()
    if (error) toast.showToast({
        title: 'An error occurred.',
        description: error?.message && typeof error?.message === 'string' ? error?.message : 'Unable to add employee.',
        status: 'error',
      })

    if (data) {
      const employeeId = data[0].employee_id;
      const fileUploadPromises = [];
      ['employee_cv', 'employee_transcript', 'employee_cnic'].forEach(field => {
        if (employeeData[field] && Array.isArray(employeeData[field])) {
          employeeData[field].forEach(file => {
            if (file instanceof File) {
              fileUploadPromises.push(uploadAttachment(file, employeeId));
            }
          });
        } else if (employeeData[field] instanceof File) {
          fileUploadPromises.push(uploadAttachment(employeeData[field], employeeId));
        }
      });
      const uploadedFilePaths = await Promise.all(fileUploadPromises);
      ['employee_cv', 'employee_transcript', 'employee_cnic'].forEach((field, index) => {
        if (uploadedFilePaths[index]) {
          employeeData[field] = uploadedFilePaths[index];
        }
      });
      const { error: updateError } = await supabase
        .from('employee')
        .update(employeeData)
        .eq('employee_id', employeeId);

      if (updateError) {
        toast.showToast({
          title: 'An error occurred.',
          description: updateError.message || 'Unable to update employee with file paths.',
          status: 'error',
        });
      }
      const empManagerData = {
        employee_id: data[0].employee_id,
        manager_id: managerId
      }
      const { error: err } = await supabase
        .from('employee_manager')
        .insert([empManagerData])
        .select()
      if (err) toast.showToast({
          title: 'An error occurred.',
          description: err?.message && typeof err?.message === 'string' ? err?.message : 'Unable to add manager.',
          status: 'error',
        })

      dispatch(setNewEmployeeData(data[0]));
      dispatch(getAllEmployees(search, page, sortBy));
      toast.showToast({
        title: 'Added.',
        description: 'Employee added successfully',
        status: 'success',
      })
    }
  } catch (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: 'Unable to add employee.',
      status: 'error',
    })
  }
};

export const editEmployee = (employee_id, manager_id, employeeData, search, page, sortBy) => async (dispatch) => {
  delete employeeData.manager_id;
  employeeData.date_of_joining = employeeData.date_of_joining.toLocaleDateString("en-US");
  const { data: currentData, error: fetchError } = await supabase
    .from('employee')
    .select('employee_cv, employee_transcript, employee_cnic')
    .eq('employee_id', employee_id)
    .single();

  if (fetchError) {
    toast.showToast({
      title: 'An error occurred.',
      description: fetchError.message || 'Unable to fetch current employee data.',
      status: 'error',
    });
    return;
  }
  const fileUploadPromises = [];
  const existingFiles = {
    employee_cv: currentData.employee_cv,
    employee_transcript: currentData.employee_transcript,
    employee_cnic: currentData.employee_cnic,
  };

  ['employee_cv', 'employee_transcript', 'employee_cnic'].forEach(field => {
    if (employeeData[field] instanceof File) {
      fileUploadPromises.push(uploadAttachment(employeeData[field], employee_id));
    } else if (Array.isArray(employeeData[field])) {
      employeeData[field].forEach(file => {
        if (file instanceof File) {
          fileUploadPromises.push(uploadAttachment(file, employee_id));
        }
      });
    }
  });
  const uploadedFilePaths = await Promise.all(fileUploadPromises);
  ['employee_cv', 'employee_transcript', 'employee_cnic'].forEach((field, index) => {
    if (uploadedFilePaths[index]) {
      employeeData[field] = uploadedFilePaths[index];
    } else {
      employeeData[field] = existingFiles[field];
    }
  });
  const { data, error } = await supabase
    .from('employee')
    .update(employeeData)
    .eq('employee_id', employee_id)
    .select()

  if (data) {
    if (manager_id) {
      const { error: err } = await supabase
        .from('employee_manager')
        .update({ manager_id: manager_id })
        .eq('employee_id', employee_id)
        .select()

      if (err) toast.showToast({
          title: 'An error occurred.',
          description: err?.message && typeof err?.message === 'string' ? err?.message : 'Unable to add manager.',
          status: 'error',
        })
    }
    dispatch(getAllEmployees(search, page, sortBy));
    toast.showToast({
      title: 'Updated.',
      description: 'Employee updated successfully',
      status: 'success',
    })
  } else if (error) {
    toast.showToast({
      title: 'An error occurred.',
      description: error?.message && typeof error?.message === 'string' ? error?.message : 'Unable to update employee.',
      status: 'error',
    })
  }
};

export const deleteEmployee = (employeeId, search, page, sortBy) => async (dispatch) => {
  const { error } = await supabase
    .from('employee')
    .delete()
    .eq('employee_id', employeeId)
  if (error) {
    toast.showToast({
      title: 'Error',
      description: error?.message && typeof error?.message === 'string' ? error?.message : 'Unable to delete employee.',
      status: 'error',
    })
    dispatch(closeDeleteConfirmation())
  } else {
    dispatch(getAllEmployees(search, page, sortBy));
    dispatch(closeDeleteConfirmation());
    toast.showToast({
      title: 'Deleted.',
      description: 'Employee deleted successfully',
      status: 'success',
    })
  }
};

export const uploadAttachment = async (file, userId) => {
  const filename = `${new Date().getTime()}_${file.name}`;
  const { data, error } = await supabase.storage.from('employment_history').upload(`assets/${userId}/${filename}`, file);

  if (error) {
    toast.showToast({
      title: 'Error',
      description: 'Unable to upload file.',
      status: 'error',
    });
    return null;
  }
  return data.path;
};

export const deleteAttachmentsFromStorage = async (files) => {
  const updated = files.map((file) =>
    file?.startsWith(`${EMPLOYMENT_HISTORY_STORAGE}/`) ? file.replace(`${EMPLOYMENT_HISTORY_STORAGE}/`, '') : file
  );
  const { error } = await supabase.storage.from('employment_history').remove(updated);

  if (error) {
    toast.showToast({
      title: 'Error',
      description: 'Unable to remove file from storage.',
      status: 'error',
    });
  }
};