import React from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer } from "@chakra-ui/react";
import Loader from "components/loader/miniLoader";
import { useSelector } from 'react-redux';

const CustomTable = ({
  columns,
  data,
  isLoading,
  minHeight,
  maxHeight,
  pageIndex,
  pageSize,
  pageCount,
  gotoPage,
  canPreviousPage,
  canNextPage,
  totalItems,
  sortBy,
  setSortBy
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex, pageSize, sortBy },
      manualPagination: true,
      manualSortBy: true,
      pageCount,
    },
    useSortBy,
    usePagination,
  );

  const { sideBarOpen } = useSelector((state) => state.layout);
  const handleSort = (column) => {
    const { id, accessor } = column;
    if (accessor) {
      const isSorted = sortBy[0] && sortBy[0].id === id;
      const isSortedDesc = sortBy[0] ? sortBy[0].desc : false;
      const newSortBy = isSorted ? [{ id, desc: !isSortedDesc }] : [{ id, desc: false }];
      setSortBy(newSortBy);
    }
  };

  return (
    <div>
      <TableContainer style={{ maxWidth: sideBarOpen ? "75vw" : "93vw", maxHeight: maxHeight ?? "60vh", overflow: "auto", minHeight: minHeight ?? "50vh" }} >
        <Table {...getTableProps()} variant="unstyled">
          <Thead>
            {headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    onClick={() => handleSort(column)}
                  >
                    {column.render('Header')}
                    <span className='text-brand-600 text-lg'>
                      {column.id === sortBy[0].id && (
                        sortBy[0].desc ? '\u25be' : '\u25b4'
                      )}
                    </span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {isLoading ? (
              <Loader dims='mt-[25vh] !ml-[22vw]' />
            ) : (
              page.map(row => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <Td className='!py-1 max-w-[270px] truncate' {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                    ))}
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <div className="flex align-center justify-between border-t border-gray-300 mx-2 px-4 pt-4 pb-2">
        <div className="text-navy-800 pt-2 dark:text-white">
          Showing <b>{((pageIndex) * pageSize) + 1}</b> to <b>{Math.min((pageIndex + 1) * pageSize, totalItems)}</b> of <b>{totalItems}</b> items
        </div>
        <div>
          <button
            title='previous page'
            onClick={() => gotoPage(pageIndex - 1)}
            disabled={!canPreviousPage}
            className="text-white linear rounded-lg bg-brand-500 px-4 py-2 text-center text-base font-medium transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:bg-gray-300 dark:disabled:bg-navy-500 disabled:cursor-not-allowed"
          >
            Previous
          </button>
          <button
            title='next page'
            onClick={() => gotoPage(pageIndex + 1)}
            disabled={!canNextPage}
            className="ml-4 text-white linear rounded-lg bg-brand-500 px-4 py-2 text-center text-base font-medium transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:bg-gray-300 dark:disabled:bg-navy-500 disabled:cursor-not-allowed"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomTable;
