import React, { useRef } from "react";
import { MdCancel, MdFileUpload } from "react-icons/md";
import { Icon, Input, Text, Box } from "@chakra-ui/react";

const ResumeFileUpload = (props) => {
  const { label, id, files, setFiles, placeholderText, subLabel, size = "md" } = props;

  const inputRef = useRef(null);

  const safeFiles = Array.isArray(files) ? files : files ? [files] : [];

  const handleFileChange = (event) => {
    const addedFiles = Array.from(event.target.files);
    setFiles([...safeFiles, ...addedFiles]);

    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleFileCancel = (e, idx) => {
    e.stopPropagation();
    e.preventDefault();
    const copyData = [...safeFiles];
    copyData.splice(idx, 1);
    setFiles(copyData);
  };

  const getFileName = (file) => {
    if (file instanceof File) {
      return file.name;
    } else if (typeof file === "string") {
      return file.startsWith("experience_attachments/") ? file.split("/").pop() : file;
    }
    return "";
  };

  const sizeClasses = {
    sm: { containerHeight: "45px", iconSize: 25 },
    md: { containerHeight: "55px", iconSize: 35 },
    lg: { containerHeight: "65px", iconSize: 45 },
  };

  const { containerHeight, iconSize } = sizeClasses[size];

  return (
    <Box mb={4}>
      {label && (
        <Text className="!text-sm text-navy-700 dark:text-white" mb={subLabel ? 0 : 2}>
          {label}
        </Text>
      )}
      {subLabel && <Text fontSize="sm" color="gray.500" mb={2}>{subLabel}</Text>}

      <label
        htmlFor={id}
        className={`cursor-pointer flex items-center border border-gray-300 rounded-xl p-2 bg-white dark:bg-navy-700 w-full ${containerHeight} overflow-hidden`}
      >
        <MdFileUpload size={iconSize} className="mr-2 text-brand-600" />
        <Box flex="1" display="flex" flexWrap="wrap" alignItems="center">
          {safeFiles.length > 2 ? (
            <span
              className="inline-flex items-center p-1 bg-brand-600 text-white rounded-md text-sm mr-2 whitespace-nowrap overflow-hidden text-ellipsis max-w-[200px]"
            >
              {`${safeFiles.length} files attached`}
              <Icon
                as={MdCancel}
                cursor="pointer"
                onClick={(e) => setFiles([])}
                color="white"
                ml={1}
                boxSize={4}
                _hover={{ color: "red.300" }}
              />
            </span>
          ) : safeFiles.length > 0 ? (
            safeFiles.map((file, index) => (
              <span
                key={index}
                className="flex items-center p-1 bg-gray-600 dark:bg-gray-600 text-black dark:text-black rounded-md text-sm mr-2 max-w-[200px] whitespace-nowrap overflow-hidden text-ellipsis"
              >
                <span className="flex-1 overflow-hidden text-ellipsis">{getFileName(file)}</span>
                <Icon
                  as={MdCancel}
                  cursor="pointer"
                  onClick={(e) => handleFileCancel(e, index)}
                  color="black"
                  ml={1}
                  boxSize={4}
                  _hover={{ color: "red.300" }}
                />
              </span>
            ))
          ) : (
            <Text color="gray.500" fontSize="sm">
              Click to Upload Files
            </Text>
          )}
        </Box>
      </label>

      <Input
        type="file"
        multiple
        id={id}
        ref={inputRef}
        display="none"
        onChange={handleFileChange}
      />
    </Box>
  );
};

export default ResumeFileUpload;
