import React from "react";

const Loader = ({ dims, height, width }) => {
  const size = `h-${height ?? 4} w-${width ?? 4}`
  return (
    <div className={`flex items-center ${dims} w-full ml-auto justify-center`}>
      <div className='flex space-x-2 justify-center items-center bg-opacity-50'>
        <span className='sr-only'>Loading...</span>
        <div className={`${size} bg-brand-500 rounded-full animate-bounce [animation-delay:-0.3s]`}></div>
        <div className={`${size} bg-brand-500 rounded-full animate-bounce [animation-delay:-0.15s]`}></div>
        <div className={`${size} bg-brand-500 rounded-full animate-bounce`}></div>
      </div>
    </div>
  );
};

export default Loader;