import React, { useState, useEffect } from 'react';
import { Flex, FormControl, Box, IconButton, FormLabel } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { FaTrash } from 'react-icons/fa';
import { MdAdd } from "react-icons/md";
import {
  addEmploymentHistory,
  fetchEmploymentHistoryByEmployeeId,
  editEmploymentHistory,
} from '../api/manageEmployeeHistory';
import InputField from 'components/fields/InputField';
import FileUpload from 'components/fields/HistoryUpload';
import customToast from 'utils/toastUtils';
import DateRangePicker from 'components/fields/DateRangePicker';

const EmploymentHistoryForm = ({ mode, employeeId }) => {
  const dispatch = useDispatch();
  const toast = customToast();

  const { newEmployeeData } = useSelector((state) => state.manageEmployees);
  const [formState, setFormState] = useState({
    total_years_experience: '',
    companyHistories: [],
  });

  const empId = mode === 'Add' ? newEmployeeData?.employee_id : employeeId;

  useEffect(() => {
    if (mode === 'Edit' && empId) {
      dispatch(fetchEmploymentHistoryByEmployeeId(empId)).then((data) => {
        if (data) {
          setFormState(data);
        }
      });
    }
  }, [dispatch, mode, empId]);

  const addCompanyHistory = () => {
    setFormState((prevState) => ({
      ...prevState,
      companyHistories: [
        ...prevState.companyHistories,
        { company_name: '', designation: '', emp_start_date: '', emp_end_date: '', experience_letter: null },
      ],
    }));
  };

  const removeCompanyHistory = (index) => {
    setFormState((prevState) => {
      const updatedHistories = [...prevState.companyHistories];
      updatedHistories.splice(index, 1);
      return { ...prevState, companyHistories: updatedHistories };
    });
  };

  const handleInputChange = (index, field, value) => {
    const updatedCompanyHistories = [...formState.companyHistories];
    updatedCompanyHistories[index][field] = value;
    setFormState({ ...formState, companyHistories: updatedCompanyHistories });
  };

  const handleFileChange = (index, field, files) => {
    const file = files[0];
    const updatedCompanyHistories = [...formState.companyHistories];
    updatedCompanyHistories[index][field] = file;
    setFormState({ ...formState, companyHistories: updatedCompanyHistories });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!empId) {
      toast.showToast({
        title: 'Error',
        description: 'Employee ID is missing.',
        status: 'error',
      });
      return;
    }

    const employmentHistoryData = {
      employee_id: empId,
      total_years_experience: formState.total_years_experience,
      companyHistories: formState.companyHistories,
    };

    if (mode === 'Add') {
      await dispatch(addEmploymentHistory(employmentHistoryData));
    } else {
      await dispatch(editEmploymentHistory(empId, employmentHistoryData));
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mx-4">
      <Flex justify="space-between" align="center" mb={6}>
        <FormControl isRequired className="flex flex-col mx-1 mb-3">
          <InputField
            type="text"
            label="Total Years of Experience"
            placeholder="Enter total years of experience"
            id="total_years_experience"
            value={formState.total_years_experience}
            onChange={(e) =>
              setFormState({ ...formState, total_years_experience: e.target.value })
            }
            required
          />
        </FormControl>
      </Flex>

      {formState.companyHistories.length > 0 && 
        formState.companyHistories.map((companyHistory, index) => (
          <Box key={index} mb={6} borderWidth={1} p={4} borderRadius="md">
            <Flex justify="space-between" mb={4} align="center">
              <FormLabel className='dark:text-white'>Company {index + 1}</FormLabel>
              {index >= 0 && (
                <IconButton
                  icon={<FaTrash />}
                  size="sm"
                  onClick={() => removeCompanyHistory(index)}
                  className='dark:text-brand-600 dark:bg-navy-700 dark:hover:bg-brand-400'
                  title='remove the company'
                />
              )}
            </Flex>

            <Flex wrap="wrap" spacing={6} mb={4} alignItems="center">
              <FormControl flex="1" mr={4} mb={4}>
                <InputField
                  type="text"
                  label="Company Name"
                  placeholder="Enter company name"
                  id={`company_name_${index}`}
                  value={companyHistory.company_name}
                  onChange={(e) =>
                    handleInputChange(index, 'company_name', e.target.value)
                  }
                />
              </FormControl>

              <FormControl flex="1" mb={4}>
                <InputField
                  type="text"
                  label="Designation"
                  placeholder="Enter designation"
                  id={`designation_${index}`}
                  value={companyHistory.designation}
                  onChange={(e) =>
                    handleInputChange(index, 'designation', e.target.value)
                  }
                />
              </FormControl>
            </Flex>

            <Flex wrap="wrap" spacing={6} mb={4} alignItems="center">
              <FormControl flex="1" mr={4} mb={4}>
                <DateRangePicker
                  placeholder={'Enter Start Date'}
                  id={`start_date_${index}`}
                  label={`Employment Period`}
                  startDate={companyHistory.emp_start_date}
                  setStartDate={(date) => handleInputChange(index, 'emp_start_date', date)}
                  endDate={companyHistory.emp_end_date}
                  setEndDate={(date) => handleInputChange(index, 'emp_end_date', date)}
                  required
                />
              </FormControl>
            </Flex>

            <FormControl flex="1" mb={4}>
              <FileUpload
                size="sm"
                label="Attach Experience Letter"
                id={`experience_letter_${index}`}
                files={companyHistory.experience_letter ? [companyHistory.experience_letter] : []}
                setFiles={(files) => handleFileChange(index, 'experience_letter', files)}
                placeholderText="Please attach your Experience Letter"
              />
            </FormControl>
          </Box>
        ))
      }
      <button
        title='add another company'
        type="button"
        className="float-left mb-3 mr-3 mt-2 rounded-md border-2 border-brand-500 px-4 py-1 text-base font-medium text-brand-500"
        onClick={addCompanyHistory}
      >
        <MdAdd className="inline mr-2" size={20} />
        Add Experience
      </button>

      <Flex justify="end" mt={4}>
        <button
          title='save the experience details'
          type="submit"
          className="linear flex items-center justify-center mt-6 mb-3 w-40 rounded-xl bg-brand-500 py-[7px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700"
        >
          Save
        </button>
      </Flex>
    </form>
  );
};

export default EmploymentHistoryForm;
