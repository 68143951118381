import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import customToast from './toastUtils';
import { GCALENDAR_REFRESH_TOKEN_COOKIE } from 'variables/common';
import { GCALENDAR_ACCESS_TOKEN_COOKIE } from 'variables/common';
import axios from 'axios';

const toast = customToast();
// call edge function to refresh token for google calendar
const getAccessToken = async (refreshToken) => {
  try {
    const payload = { refresh_token: refreshToken };
    const response = await axios.post(
      `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/refresh-token`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          'apikey': process.env.REACT_APP_SUPABASE_KEY,
          'Authorization': `Bearer ${process.env.REACT_APP_SUPABASE_KEY}`,
        },
      }
    );
    if (response.status === 200) return response.data;;
  }
  catch (error) {
    console.error('Error refreshing token:', error);
    return undefined;
  }
}

const useGoogleCalendar = () => {
  const [gapiLoaded, setGapiLoaded] = useState(false);
  const [gisLoaded, setGisLoaded] = useState(false);
  const [gapiClient, setGapiClient] = useState(null);
  const [tokenClient, setTokenClient] = useState(null);

  const accessToken = Cookies.get(GCALENDAR_ACCESS_TOKEN_COOKIE); // Fetch access token from cookies
  const refreshToken = Cookies.get(GCALENDAR_REFRESH_TOKEN_COOKIE); // Fetch refresh token from cookies
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';
  const SCOPES = 'https://www.googleapis.com/auth/calendar.events';

  // Handle token response and store in cookies
  const handleTokenResponse = (response) => {
    const { access_token, expires_in } = response;
    const expiryTime = new Date(new Date().getTime() + expires_in * 1000);

    if (access_token) {
      Cookies.set(GCALENDAR_ACCESS_TOKEN_COOKIE, access_token, { expires: expiryTime });
    }
  };

  // Load Google API
  useEffect(() => {
    const loadGapi = () => {
      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/api.js';
      script.onload = () => {
        window.gapi.load('client', async () => {
          try {
            await window.gapi.client.init({
              apiKey: API_KEY,
              discoveryDocs: [DISCOVERY_DOC],
            });
            setGapiClient(window.gapi);
            setGapiLoaded(true);
          } catch (error) {
            console.error('Error initializing GAPI client:', error);
            setGapiLoaded(false);
          }
        });
      };
      script.onerror = () => {
        console.error('Error loading GAPI script.');
        setGapiLoaded(false);
      };
      document.body.appendChild(script);
    };

    const loadGis = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.onload = () => {
        try {
          const tokenClient = window.google.accounts.oauth2.initTokenClient({
            client_id: CLIENT_ID,
            scope: SCOPES,
            callback: (response) => handleTokenResponse(response),
          });
          setTokenClient(tokenClient);
          setGisLoaded(true);
        } catch (error) {
          console.error('Error initializing GIS client:', error);
          setGisLoaded(false);
        }
      };
      script.onerror = () => {
        console.error('Error loading GIS script.');
        setGisLoaded(false);
      };
      document.body.appendChild(script);
    };

    loadGapi();
    loadGis();
  }, []);

  // Refresh Access Token using Backend
  const refreshAccessToken = async () => {
    if (!refreshToken) {
      console.error('No refresh token available.');
      return null;
    }
    try {
      const resp = await getAccessToken(refreshToken)
      if (resp && resp.access_token) {
        handleTokenResponse(resp)
        return resp.access_token;
      }
    } catch (error) {
      console.error('Error refreshing access token:', error);
      return null;
    }
  };

  const getAccessTokenByClient = () => {
    console.log("Attempting to get token by client")
    if (gisLoaded) {
      tokenClient.callback = (response) => handleTokenResponse(response);
      tokenClient.requestAccessToken({ prompt: '' });
    } else console.log("Gsi client could not be loaded")
  };

  // Connect Google Calendar
  const connectGoogleCalendar = async () => {
    let token = accessToken;
    if (!token) {
      token = await refreshAccessToken();
    }
    if (token) {
      gapiClient.client.setToken({ access_token: token });
    } else {
      console.error('Failed to get access token from api.');
      // Refresh token using prompt
      getAccessTokenByClient()
    }
  };

  // Create an Out of Office Event
  const createOutOfOfficeEvent = async (startDateTime, endDateTime) => {
    try {
      const event = {
        summary: 'Out of Office',
        start: { dateTime: startDateTime, timeZone: 'America/Los_Angeles' },
        end: { dateTime: endDateTime, timeZone: 'America/Los_Angeles' },
        eventType: 'outOfOffice',
        transparency: 'opaque',
        // outOfOfficeProperties: {
        //   autoDeclineMode: 'declineAllConflictingInvitations',
        //   declineMessage: 'Employee is on leave',
        // },
      };

      const response = await gapiClient.client.calendar.events.insert({
        calendarId: 'primary',
        resource: event,
      });

      toast.showToast({
        title: 'Info',
        description: 'Added event to your calendar',
        status: 'info',
      });
      console.log('Event created:', response.result.htmlLink);
    } catch (error) {
      console.error('Error creating event:', error);
      toast.showToast({
        title: 'Something went wrong',
        description: 'Calendar event could not be added',
        status: 'warning',
      });
    }
  };

  return { createOutOfOfficeEvent, connectGoogleCalendar, gapiLoaded };
};

export default useGoogleCalendar;
