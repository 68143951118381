import { supabase } from "utils/supabase";
import customToast from "utils/toastUtils";

const toast = customToast();
export const getCalendarHolidays = async () => {
  let holidays = []
  const { data, error } = await supabase
    .from('public_holidays')
    .select('*')
  if (error) {
    console.error('Error fetching holidays:', error);
    toast.showToast({
      title: 'An error occurred.',
      description: error?.message || 'Holiday data could not be fetched.',
      status: 'error',
    });
  } else if (data && data.length > 0) {
    holidays = data
  }
  return holidays
}