import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
  calendarData: [],
  holidays: [],
  isLoading: false,
  range: {
    start: moment().startOf('month').format('YYYY-MM-DD'), 
    end: moment().endOf('month').format('YYYY-MM-DD'), 
  },
};

const leavesCalendarReducers = createSlice({
  name: 'leavesCalendar',
  initialState,
  reducers: {
    setHolidaysList: (state, action) => {
      state.holidays = action.payload
    },
    setCalendarEvents: (state, action) => {
      state.calendarData = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setRange: (state, action) => {
      state.range = action.payload
    }
  },
});

export const {
  setCalendarEvents,
  setHolidaysList,
  setIsLoading,
  setRange
} = leavesCalendarReducers.actions;

export default leavesCalendarReducers.reducer;
