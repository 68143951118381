import React, { useEffect, useState } from "react";
import { Card } from "@chakra-ui/react";
import SearchInput from "components/search/Search";
import { MdAdd, MdEdit, MdExpandMore, MdExpandLess } from "react-icons/md";
import EmployeeForm from "./components/EmployeeForm";
import { useDispatch, useSelector } from 'react-redux';
import { openAddForm, openEditForm, setSearch, setPage, closeForm, setSort, setFilters, resetFilters 
} from "./reducers/manageEmployeesReducers";
import { getAllDepartments, getAllEmployeeRoles, getAllEmployees, getEmployeeManagers } from "./api/manageEmployees";
import { PAGINATION_DEFAULT_PAGE_SIZE } from "variables/common";
import { formatDisplayDate } from "utils/dateUtils";
import CustomTable from "../tables/components/CustomTable";
import FilterOverlay from "components/fields/FilterOverlay";

const ManageEmployees = () => {
  const {
    isFormOpen, employees, mode, selectedEmployee, search, page, pagination, 
    isLoading, sortBy, filters,departments,managers,employeeRoles
  } = useSelector((state) => state.manageEmployees);
  const dispatch = useDispatch();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    dispatch(getAllEmployeeRoles());
    dispatch(getEmployeeManagers());
    dispatch(getAllDepartments());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllEmployees(search, page, sortBy, filters));
    dispatch(closeForm());
  }, [dispatch, search, page, sortBy, filters]);

  const handlePageChange = (newPage) => {
    dispatch(setPage(newPage + 1)); 
  };

  const handleSortChange = (newSortBy) => {
    dispatch(setSort(newSortBy));
  };

  const handleReset = () => {
    dispatch(resetFilters());
  };

  const pageSize = PAGINATION_DEFAULT_PAGE_SIZE;
  const data = React.useMemo(() => employees, [employees]);
  const columns = React.useMemo(() => [
    {
      Header: 'Name',
      accessor: 'employee_name',
    },
    {
      Header: 'Email',
      accessor: 'employee_email',
    },
    {
      Header: 'DOJ',
      accessor: 'date_of_joining',
      Cell: ({ value }) => (value ? formatDisplayDate(value) : value),
    },
    {
      Header: 'Department',
      accessor: 'department_label',
    },
    {
      Header: 'Manager',
      accessor: 'manager_name',
    },
    {
      Header: 'Edit',
      disableSortBy: true,
      Cell: ({ row }) => (
        <>
        <button
          title="open edit info form"
          onClick={() => dispatch(openEditForm(row.original))}
          className="text-xl mr-4 text-brand-500 hover:text-brand-600 active:text-brand-700 dark:text-white"
        >
          <MdEdit size={25} />
        </button>
         {/* <button
            onClick={() => dispatch(openDeleteConfirmation(row.original))}
            className="text-xl text-red-500 hover:text-red-600 active:text-red-700 dark:text-white"
          >
            <MdDelete size={25} />
          </button> */}
        </>
      ),
    },
  ], [dispatch]);

  return (
    <>
      {isFormOpen && <EmployeeForm
        mode={mode}
        data={selectedEmployee}
      />}
      <div className="my-6 ml-4">
        <Card className="bg-white dark:!bg-navy-800 pl-4 pr-2 pb-2 dark:shadow-none dark:text-lightPrimary ">
          <div className="flex mb-3 mx-[1rem] h-full items-center justify-between mt-4">
            {!isFilterOpen ? (
              <SearchInput
                value={search}
                onChange={(val) => dispatch(setSearch(val))}
                placeholder="Search..."
              />
            ) : (
              <div className="w-full mt-4"></div>
            )}
            <div className="flex items-center space-x-4">
            <button
              onClick={() => setIsFilterOpen(!isFilterOpen)}
              className="linear flex items-center justify-center w-36 rounded-xl bg-brand-500 py-[5px] text-base font-medium text-white"
              title={isFilterOpen ? 'Close filters' : 'Open filters'} // Native title attribute
            >
              Filters
              {isFilterOpen ? <MdExpandLess className="ml-2" size={18} /> : <MdExpandMore className="ml-2" size={18} />}
            </button>
              {!isFormOpen && (
                <button
                  title="open add info form"
                  onClick={() => dispatch(openAddForm())}
                  className="text-xl text-brand-500 hover:text-brand-600 active:text-brand-700 dark:text-brand-700"
                >
                  <MdAdd size={35} />
                </button>
              )}
            </div>
          </div>

          {isFilterOpen && (
            <FilterOverlay
              departments={departments}
              managers={managers}
              employeeRoles={employeeRoles}
              onFilterSubmit={(newFilters) => {
                dispatch(setFilters(newFilters));
                dispatch(setPage(1));
              }}
              onReset={handleReset}
              formState={filters}
            />
          )}

          <CustomTable
            columns={columns}
            data={data}
            isLoading={isLoading}
            pageIndex={page - 1}
            pageSize={pageSize}
            pageCount={Math.ceil(pagination.total / pageSize)}
            gotoPage={handlePageChange}
            canPreviousPage={page > 1}
            canNextPage={page * pageSize < pagination.total}
            totalItems={pagination.total}
            sortBy={sortBy} 
            setSortBy={handleSortChange}
          />
        </Card>
      </div>
      {/* <CustomAlertDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={() => dispatch(closeDeleteConfirmation())}
        onConfirm={() => {
          if (selectedEmployee && selectedEmployee?.employee_id) dispatch(deleteEmployee(selectedEmployee.employee_id, search, page, sortBy))
        }}
        header="Confirm Delete"
        body="Are you sure you want to delete this employee?"
        confirmText="Delete"
      /> */}
    </>
  )
}

export default ManageEmployees;
