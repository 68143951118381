import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
// import Footer from "components/footer/Footer";
import routes from "routes.js";
import { useDispatch, useSelector } from "react-redux";
import { setSideBar } from "layouts/reducers/layoutReducers";
import { setCurrentRoute } from "layouts/reducers/layoutReducers";
import Login from "views/auth/Login";
import { supabase } from "utils/supabase";
import { setSession } from "layouts/reducers/authReducers";
import { fetchEmployeeByEmail } from "views/admin/manageEmployees/api/manageEmployees";
import customToast from "utils/toastUtils";
import Loader from "components/loader";
import { setLoading } from "layouts/reducers/layoutReducers";
import { handleSignOut } from "components/navbar";
import { GCALENDAR_ACCESS_TOKEN_COOKIE } from "variables/common";
import { GCALENDAR_REFRESH_TOKEN_COOKIE } from "variables/common";
import Cookies from "js-cookie";

const toast = customToast();
export default function SharedLayout(props) {
  const { ...rest } = props;
  const location = useLocation();
  const { sideBarOpen, currentRoute, loading } = useSelector((state) => state.layout);
  const { session, employee } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLoading(true));
    supabase.auth.getSession().then(({ data: session, error }) => {
      if (error) {
        console.error('Error fetching session:', error.message);
        return;
      }

      if (session && session?.session) {
        dispatch(setSession(session?.session));
        // Save tokens to cookies
        const { provider_token, provider_refresh_token, expires_at } = session?.session;
        if (provider_token && provider_refresh_token) {
          Cookies.set(GCALENDAR_ACCESS_TOKEN_COOKIE, provider_token, {
            expires: new Date(expires_at * 1000), // Google expiry in seconds
          });
          Cookies.set(GCALENDAR_REFRESH_TOKEN_COOKIE, provider_refresh_token, {
            expires: 90,   // valid for 90 days
          });
        }
      };
      dispatch(setLoading(false));
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      if (session && session?.session) dispatch(setSession(session?.session));
    });

    return () => subscription.unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? dispatch(setSideBar(false)) : dispatch(setSideBar(true))
    );
  }, [dispatch]);

  useEffect(() => {
    if (session && session?.user && session?.user?.email) {
      dispatch(setLoading(true));
      dispatch(fetchEmployeeByEmail(session?.user?.email)).then(emp => {
        if (!emp) toast.showToast({
          title: 'Login Failed.',
          description: 'Employee data could not be found. Please try again or contact HR.',
          status: 'error',
        })
        else if (emp && !emp?.employee_status) toast.showToast({
          title: 'Login Failed.',
          description: 'Employee has been inactivated. Please contact HR manager.',
          status: 'error',
        })
        dispatch(setLoading(false));
      });
    }
  }, [session, dispatch]);

  useEffect(() => {
    if (employee && employee?.employee_id) {
      // On page load, check for tokens
      const accessToken = Cookies.get(GCALENDAR_ACCESS_TOKEN_COOKIE);
      const refreshToken = Cookies.get(GCALENDAR_REFRESH_TOKEN_COOKIE);
      if (!accessToken && !refreshToken) {
        dispatch(handleSignOut());
      }
    }
  }, [dispatch, employee]);

  useEffect(() => {
    getActiveRoute(routes);
    // eslint-disable-next-line
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "My Leaves";
    for (let i = 0; i < routes.length; i++) {
      const layoutPath = routes[i].layout === "/" ? "" : routes[i].layout
      if (
        window.location.href.indexOf(
          layoutPath + "/" + routes[i].path
        ) !== -1
      ) {
        dispatch(setCurrentRoute(routes[i].name))
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      const layoutPath = routes[i].layout === "/" ? "" : routes[i].layout
      if (
        window.location.href.indexOf(layoutPath + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/hr" && employee && (employee.employee_role_name === "hr-manager" || employee.employee_role_name === "top-management")) {
        return (
          <Route path={`${prop.layout}/${prop.path}`} element={prop.component} key={key} />
        );
      } else if (prop.layout === "/manage" && employee && (employee.employee_role_name === "hr-manager" || employee.employee_role_name === "reporting-manager" || employee.employee_role_name === "top-management")) {
        return (
          <Route path={`${prop.layout}/${prop.path}`} element={prop.component} key={key} />
        );
      }
      else if (
        prop.layout === "/" && employee && (employee.employee_role_name)
      ) {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      }
      else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  // if (!session || !employee) return <Login />
  return (
    <>
      {loading && <Loader />}
      {(!session || !employee || !employee?.employee_status) ? <Login /> : (
        <div className="flex h-full w-full">
          <Sidebar open={sideBarOpen} onClose={() => dispatch(setSideBar(false))} />
          {/* Navbar & Main Content */}
          <div className={`h-full pb-5 w-full bg-lightPrimary dark:!bg-navy-900 ${sideBarOpen ? 'ml-[256px] transition-all' : ''}`}>
            {/* Main Content */}
            <main className={`mx-[12px] h-full flex-none ${sideBarOpen ? 'md:pr-2' : 'md:pr-2'}`}>
              {/* Routes */}
              <div className="h-full">
                <Navbar
                  onOpenSidenav={() => dispatch(setSideBar(!sideBarOpen))}
                  logoText={"HR App"}
                  brandText={currentRoute}
                  secondary={getActiveNavbar(routes)}
                  {...rest}
                />
                <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
                  <Routes>
                    {getRoutes(routes)}

                    <Route
                      path="/"
                      element={<Navigate to="/my-leaves" replace />}
                    />
                  </Routes>
                </div>
                {/* <div className="p-3">
              <Footer />
            </div> */}
              </div>
            </main>
          </div>
        </div>
      )}
    </>
  );
}
