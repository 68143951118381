import React, { useState } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Card } from '@chakra-ui/react';
import { MdPerson, MdFamilyRestroom, MdDevices, MdAccountBalance, MdWork, MdLocalHospital, MdContacts, MdMinimize } from "react-icons/md";
import EmployeeInfoForm from "./EmployeeinfoForm";
import ContactInfoForm from "./ContactinfoForm";
import FamilyDetailsForm from "./FamilydetailForm";
import AssetsForm from "./AssetsForm";
import BankDetailsForm from "./BankdetailForm";
import EmploymentHistoryForm from "./EmploymenthistoryForm";
import MedicalInformationForm from "./MedicalinfoForm";
import { closeForm } from "../reducers/manageEmployeesReducers";
import { useDispatch, useSelector } from "react-redux";

const EmployeeForm = ({ mode, data }) => {
  const dispatch = useDispatch();
  const { newEmployeeData, isFormOpen } = useSelector((state) => state.manageEmployees);
  const employeeId = mode === 'Add' ? newEmployeeData?.employee_id : data?.employee_id;

  // Controlled tab state
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Card className="bg-white dark:!bg-navy-800 px-4 pb-2 dark:shadow-none ml-5">
      <div className="flex mb-3 mx-[1rem] h-full items-center justify-between">
        <div className="text-xl mt-8 mb-3 ml-1 font-bold text-navy-700 dark:text-white">
          {mode === "Edit" ? "Edit Employee" : "New Employee"}
        </div>
        <button
          onClick={() => dispatch(closeForm())}
          className="text-xl text-brand-500 hover:text-brand-600 active:text-brand-700 dark:text-brand-700"
          title={mode === "Edit" ? 'Close edit info form' : 'Close add info form'} // Native title attribute
        >
          <MdMinimize size={35} />
        </button>
      </div>
      {isFormOpen && (
        <Tabs
          isFitted
          index={activeTab} // Controlled tab index
          onChange={(index) => setActiveTab(index)} // Change handler to update tab
        >
          <TabList>
            <Tab
              fontSize={'sm'}
              fontWeight={'bold'}
              className="text-black dark:text-white"
              _selected={{ color: '#F28820' }}
            >
              <MdPerson size={20} className="mr-2 text-brand-500" />Info
            </Tab>
            <Tab
              fontSize={'sm'}
              fontWeight={'bold'}
              className="text-black dark:text-white"
              _selected={{ color: '#F28820' }}
            >
              <MdContacts size={20} className="mr-2 text-brand-500" />Contact Info
            </Tab>
            <Tab
              fontSize={'sm'}
              fontWeight={'bold'}
              className="text-black dark:text-white"
              _selected={{ color: '#F28820' }}
            >
              <MdFamilyRestroom size={20} className="mr-2 text-brand-500" />Family Details
            </Tab>
            <Tab
              fontSize={'sm'}
              fontWeight={'bold'}
              className="text-black dark:text-white"
              _selected={{ color: '#F28820' }}
            >
              <MdDevices size={20} className="mr-2 text-brand-500" />Asset Details
            </Tab>
            <Tab
              fontSize={'sm'}
              fontWeight={'bold'}
              className="text-black dark:text-white"
              _selected={{ color: '#F28820' }}
            >
              <MdAccountBalance size={20} className="mr-2 text-brand-500" />Bank Details
            </Tab>
            <Tab
              fontSize={'sm'}
              fontWeight={'bold'}
              className="text-black dark:text-white"
              _selected={{ color: '#F28820' }}
            >
              <MdWork size={20} className="mr-2 text-brand-500" />Experience
            </Tab>
            <Tab
              fontSize={'sm'}
              fontWeight={'bold'}
              className="text-black dark:text-white"
              _selected={{ color: '#F28820' }}
            >
              <MdLocalHospital size={20} className="mr-2 text-brand-500" />Medical Info
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              {activeTab === 0 && <EmployeeInfoForm mode={mode} data={data} />}
            </TabPanel>
            <TabPanel>
              {activeTab === 1 && <ContactInfoForm mode={mode} employeeId={employeeId} />}
            </TabPanel>
            <TabPanel>
              {activeTab === 2 && <FamilyDetailsForm mode={mode} employeeId={employeeId} />}
            </TabPanel>
            <TabPanel>
              {activeTab === 3 && <AssetsForm mode={mode} employeeId={employeeId} />}
            </TabPanel>
            <TabPanel>
              {activeTab === 4 && <BankDetailsForm mode={mode} employeeId={employeeId} />}
            </TabPanel>
            <TabPanel>
              {activeTab === 5 && <EmploymentHistoryForm mode={mode} employeeId={employeeId} />}
            </TabPanel>
            <TabPanel>
              {activeTab === 6 && <MedicalInformationForm mode={mode} employeeId={employeeId} />}
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </Card>
  );
};

export default EmployeeForm;
